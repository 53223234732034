import React from "react";
import "./AboutUs.css";
import imgAboutUs from "./aboutus.jpg";
import imgTeam from "./aboutus2.jpg";

function AboutUs() {
  return (
    <div id="Nosot" className="aboutus">
      <div className="aboutus__header">
        <h2>Desafiamos los límites: DesarrolloFit, pioneros en la revolución tecnológica.</h2>
        <p>Innovación disruptiva, soluciones a la vanguardia y resultados transformadores que marcan la diferencia en la industria.</p>
      </div>
      <div className="aboutus__content">
        <div className="aboutus__img-container">
          <img src={imgAboutUs} alt="Imagen de About Us" />
        </div>
        <div className="aboutus__img-container">
          <img src={imgTeam} alt="Imagen de About Us" />
        </div>
        <div className="aboutus__text">
          {/* <p>Cada día trae nuevos desafíos</p>
          <p>Agencia creativa enfocada en visión, producto y personas</p> */}
          <p>En DesarrolloFit, nuestro compromiso es brindar resultados excepcionales y un valor excepcional a nuestros clientes. Nos esforzamos por establecer relaciones sólidas y duraderas con cada uno de nuestros clientes, colaborando estrechamente con ellos para comprender sus objetivos y desafíos. Nuestro equipo altamente capacitado y apasionado está dedicado a entregar soluciones de alta calidad, utilizando las últimas tecnologías y las mejores prácticas de la industria. Nos enorgullece poder ofrecer un servicio personalizado y un soporte confiable, asegurando que nuestros clientes obtengan el máximo beneficio de nuestras soluciones.</p>
        </div>
        
      </div>
    </div>
  );

}
export default AboutUs;