import React, { useState } from 'react';
import './Menu.css';
import logoImg from './logo.png';

function Menu() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header>
      <div className="container">
        <nav className={isOpen ? 'menu--open' : ''}>
          <div className="menu__header">
            <div className="menu__logo">
              <img src={logoImg} alt="Logo de mi sitio web" />
            </div>
            <div className="menu__close" onClick={toggleMenu}>
              <span className="menu__close-icon"></span>
            </div>
          </div>
          <ul className="menu__list">
            <li className="menu__item"><a href="#">Home</a></li>
            <li className="menu__item"><a href="#Nosot">Nosotros</a></li>
            <li className="menu__item"><a href="#Equi">Equipo</a></li>
            <li className="menu__item"><a href="#serv">Servicio</a></li>
            <li className="menu__item"><a href="#contac">Contacto</a></li>
          </ul>
        </nav>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="menu-icon__line"></div>
          <div className="menu-icon__line"></div>
          <div className="menu-icon__line"></div>
        </div>
      </div>
    </header>
  );
}

export default Menu;