import React from 'react';
import './Footer.css';
import logoImg from './logo.png';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-left">
        <img src={logoImg} alt="Logo de mi sitio web" />
        </div>
        <div className="footer-center">
          <p>(+1) 212-946-2701</p>
          <p>soporte@desarrollofit.com</p>
        </div>
      </div>
      <div className="footer-line"></div>
      <div className="footer-bottom">
        <p>&copy; 2023 Desarrollofit. Made with ❤️ by HasThemes.</p>
      </div>
    </footer>
  );
};
export default Footer;